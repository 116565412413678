export function initUpButton(upButton: HTMLElement): void {

  function checkPosition() {
    if (window.scrollY > 300) {
      upButton.style.display = 'flex'
    } else {
      upButton.style.display = 'none'
    }
  }
  window.addEventListener('scroll', checkPosition)

  checkPosition()

  upButton.addEventListener('click', () => window.scrollTo({ top: 0, behavior: 'smooth' }))
}