export class ErrorsProcessing {
  constructor(private readonly container: HTMLElement) {
  }

  readonly resetErrors = (): void => {
    this.container.innerHTML = ""
  }
  readonly setErrors = (errors: string[]): void => {
    if (errors.length) {
      this.container.innerHTML = errors.reduce((acc, err) => {
        acc += `<p>${err}</p>`
        return acc
      }, "")
    }
  }
}