type IRecaptchaId = string | number

interface IRecaptcha {
  ready: (callback: () => void) => void
  render: (element: string, options: {
    sitekey: string
  }) => IRecaptchaId
  reset: () => void
  getResponse: (id: IRecaptchaId) => string | null
}

export class Recaptcha {
  private recaptcha?: IRecaptcha
  private captchaElementId: string

  private captchaId?: IRecaptchaId

  constructor(sitekey: string | undefined, captchaElementId: string) {
    this.captchaElementId = captchaElementId
    if ('grecaptcha' in window && window.grecaptcha) {
      this.recaptcha = window.grecaptcha as IRecaptcha
      if (sitekey) {
        this.init(sitekey)
      }
    }

    const captchaElement = document.getElementById(captchaElementId)
    if (captchaElement && (!this.recaptcha || !sitekey)) {
      captchaElement.style.display = 'none'
    }

  }

  private readonly init = (sitekey: string): void => {
    this.recaptcha?.ready(() => {
      this.captchaId = this.recaptcha?.render(this.captchaElementId, {
        sitekey: sitekey
      });
    });
  }

  public readonly getRecaptchaResponse = (): string | null => {
    if (typeof this.captchaId === 'undefined') {
      return null
    }

    return this.recaptcha?.getResponse(
      this.captchaId
    ) ?? null
  }

  public readonly reset = (): void => {
    this.recaptcha?.reset()
  }
}