export class Notification {
  constructor(private readonly element?: HTMLElement) {

  }

  public readonly hide = () => {
    if (this.element) {
      this.element.style.display = 'none'
      this.element.classList.remove('animate')
    }
  }

  public readonly show = () => {
    if (this.element) {
      this.element.style.display = 'block'
      this.element.style.top = `${window.screenTop}px`
      this.element.classList.add('animate')
    }
  }

}