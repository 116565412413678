import { FormSubmission } from "./formSubmission"
import { Notification } from "./Notification"
import { initUpButton } from "./upButton"
import { Recaptcha } from "./Recaptcha"
import { ErrorsProcessing } from "./ErrorsProcessing"


window.addEventListener('load', () => {
  const formElement = document.querySelector('#feedback form')
  let successNotification = document.querySelector('#notification-success')

  const notificationElement = new Notification(successNotification instanceof HTMLElement ? successNotification : undefined)

  const recaptcha = new Recaptcha(process.env.GOOGLE_RECAPTCHA_SITE_KEY, "captcha_element")
  formElement
  const errorElement = formElement?.querySelector('.feedback-form__errors');

  if (formElement instanceof HTMLFormElement && errorElement instanceof HTMLElement) {

    const errorsProcessing = new ErrorsProcessing(errorElement)

    const form = new FormSubmission(formElement, process.env.API_URL + "/feedback", {
      onSubmit: () => {
        errorsProcessing.resetErrors()
        recaptcha.reset()
      },
      onSuccess: () => {
        notificationElement.show()
        formElement.reset()

        setTimeout(() => {
          notificationElement.hide()
        }, 3000)
      },
      onFailure: (value) => {
        if (typeof value.message === 'string') {
          errorsProcessing.setErrors(["Captcha is required"])
        } else if (Array.isArray(value.message)) {
          errorsProcessing.setErrors(value.message)
        }
      },
      getRecaptchaResponse: () => recaptcha.getRecaptchaResponse()
    })
  }

  const upButton = document.querySelector(".button-up")
  if (upButton instanceof HTMLElement) {
    initUpButton(upButton)
  }
})