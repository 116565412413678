import { ServerResponse, CreateFeedbackFailureResponse } from "./types"

export interface FormSubmissionErrors {
  error: string,
  message?: string[]
}
export interface FormSubmissionCaptchaError {
  message: string
}

export class FormSubmission {
  formElement: HTMLFormElement
  apiUrl: string
  onSuccess: () => void
  onSubmit?: () => void
  onFailure: (value: FormSubmissionErrors | FormSubmissionCaptchaError) => void
  getRecaptchaResponse: () => string | null

  constructor(formElement: HTMLFormElement, apiUrl: string, {
    onSubmit,
    onSuccess,
    onFailure,
    getRecaptchaResponse
  }: {
    onSubmit?: () => void,
    onSuccess: () => void,
    onFailure: (value: FormSubmissionErrors | FormSubmissionCaptchaError) => void,
    getRecaptchaResponse: () => string | null
  }) {
    this.formElement = formElement
    this.apiUrl = apiUrl
    this.onSubmit = onSubmit
    this.onSuccess = onSuccess
    this.onFailure = onFailure
    this.getRecaptchaResponse = getRecaptchaResponse

    formElement.addEventListener('submit', this.submitForm)
  }

  private readonly submitForm = (event): void => {
    event.preventDefault()

    const data = new FormData(this.formElement);

    const body: Record<string, string> = {}
    for (const [name, value] of data) {
      if (typeof value === 'string') {
        body[name] = value
      }
    }

    this.submitRequest(body)
  }

  private readonly submitRequest = async (data: Record<string, string>): Promise<void> => {

    try {
      const headers = new Headers()
      headers.append('Content-Type', 'application/json');

      const recaptchaResponse = this.getRecaptchaResponse()

      if (recaptchaResponse) {
        headers.append('recaptcha', recaptchaResponse);
      }

      if (typeof this.onSubmit === 'function') {
        this.onSubmit()
      }

      const request = await fetch(this.apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
      })
      const response: ServerResponse | CreateFeedbackFailureResponse = await request.json()

      if ('ok' in response) {
        if (response.ok) {
          this.onSuccess()
          return
        } else {
          this.onFailure({ error: response.error })
          return
        }
      }

      if ('message' in response) {
        this.onFailure(response)
      }

    } catch (error) {
      this.onFailure({ message: "Что-то пошло не так, попробуйте позже" })
    }

  }
}